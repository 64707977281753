











































































































































.option{
  display: flex;
  justify-content: flex-start;
align-items: center;
}
.custom-input-wrap{
  margin-bottom:16px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.field-selecttype__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;
}
.field-selecttype__label {
  width: 48%;
  min-height: 100%;
  text-align: center;
  padding: 15px;
  position: relative;
  border: 3px solid transparent;
  box-shadow: 0px 10px 30px #9393931A;

  &:hover {
    border: 3px solid#ff8141;
    border-radius: 5px;
    box-shadow: 0px 10px 30px #9393931A;

    .field-selecttype__recommended {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.active {
    border: 3px solid#ff8141;
    border-radius: 5px;
    box-shadow: 0px 10px 30px #9393931A;

    .field-selecttype__recommended {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  img {
    height: 25px;
  }

  .strong {
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
  }

  .field-selecttype__recommended {
    position: absolute;
    top: -1px;
    left: -1px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 12px;
    padding: 0 7px;
    background-color: #FF8141;
  }

  .field-selecttype__description {
    font-size: 12px;
    padding: 10px 25px 0;
  }

  button {
    background-color: #FF8141;
    box-shadow: 0px 3px 6px #0000001A;
    border-radius: 5px;
    padding: 10px 35px;
    color: #ffffff;
    margin-top: 10px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .field-selecttype__icon-mobile {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .field-selecttype__container {
    flex-direction: column;

    .field-selecttype__label {
      width: 95%;

      &:first-child {
        margin-bottom: 10px;
      }

      &:hover {
        border: 3px solid transparent;
      }

      &.active {
        &:hover {
          border: 3px solid#ff8141;
          border-radius: 5px;
          box-shadow: 0px 10px 30px #9393931A;
        }
      }
    }

    .field-selecttype__icon-desktop {
      display: none;
    }
    .field-selecttype__icon-mobile {
      display: inline-block;
      height: 25px;
    }
  }
}
